import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Stories from "../components/Videos";

function VideosContainer() {

    const [hoverActive, setHoverActive] = useState(false);
    const [hoverUrl, setHoverUrl] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleHover = (active, url) => {
        if (hoverUrl == "") {
            console.log('cambie a activo')
            setHoverUrl(url)
        }
    }
    const handleHoverOut = (active, url) => {
        if (hoverUrl != "") {
            console.log('desactivo')
            setHoverUrl("")
        }
    }

    return (
        <>
            <Helmet>
                <title>Stories of Hope | But God Book</title>
                <meta
                    name="description"
                    content="The But God Book features 40 people's individual stories of Christ's power and love in their lives. Read on for a sneak peak."
                />
            </Helmet>
            <Stories
                screenSize={windowWidth}
                handleHover={handleHover}
                handleHoverOut={handleHoverOut}
                hoverUrl={hoverUrl}
            />
        </>

    );
}

export default VideosContainer;