import React from "react";
import { Link } from "react-router-dom";
//import "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import Navbar from "../components/NavbarTransparent";

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleHover, handleHoverOut, hoverUrl }) => {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            height: '100vh',
            overflow: `auto`,
        },
        titleContainer: {
            display: "flex",
            //    alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   zIndex: 100,
            //    height: '100px',
            fontSize: `${screenSize > 800 ? ("180px") : ("70px")}`,
            color: '#000',
            width: '100vw',
            padding: '0px 50vw 0px 45px',
            margin: "25vh 0px 0vh",
            padding: `${screenSize > 800 ? ('0px 40vw 0px 45px') : ('0px 40px')} `,
            margin: `${screenSize > 800 ? ("25vh 0px 0vh") : ("30vh 0px 5vh")} `,
            fontFamily: "BigCaslon",
            fontWeight: 500,
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
            position: "relative",
            zIndex: '100',
            top: `${screenSize > 800 ? ('150px') : ('220px')} `,
            left: 0,
            // right: 0,
        },
        textContainer: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: `${screenSize > 800 ? ("20px 0px") : ("20px 0px")} `,
        },
        textDescription: {
            margin: `${screenSize > 800 ? ("30px 33vw") : ("20px 20vw")} `,
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        imageContainer: {
            display: "flex",
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: "50px 0px"
        },
        imagePadding: {
            padding: '10px',
            backgroundColor: '#000',
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            width: `${screenSize > 800 ? (<></>) : ("80%")} `,
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            fontSize: 16,
            color: '#000',
            width: '391px',
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: "100px 0px 130px",
            // backgroundColor: 'red',
            borderRadius: '50px',
            border: '1px solid #000',
            // fontWeight: 700,
            // fontFamily: "Nimbus",
            // lineHeight: '19.2px',
        },
        footer: {
            display: "flex",
            margin: "0px 60px 150px",
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooterLeft: {

        },
        singleFooter: {
            display: "flex",
            flexDirection: 'column',
            flex: 1,
            height: '100%',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: `${screenSize > 800 ? ("0px 0px 0px 60px") : ("0px 20px")}`,
            margin: '50px 0px 0px'
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: `${screenSize > 800 ? ("921px") : ("80vw")}`,
            height: "2px",
            alignSelf: 'flex-start',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            backgroundColor: '#000'
        },
        textDate: {
            display: "flex",
            margin: "20px 0px 0px",
            fontSize: 12,
            color: '#000',
            lineHeight: "14px",
        },
        moreStoriesText: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            margin: "100px 0px 83px",
            margin: `${screenSize > 800 ? ("100px 0px 83px") : ("70px 0px 30px")}`,
            //margin: "30px 30vw",
            fontSize: 12,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "14px",
            fontWeight: 700
        },
        storiesContainer: {
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            // fontSize: 20,
            // color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ("0px 50px") : ("0px 5px")}`,
            margin: `${screenSize > 800 ? ("0px 0px 150px") : ("0px 0px 100px")}`,
            // backgroundColor: 'yellow'
        },
        photo: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            //  margin: '0px 10px'
            margin: '0px'
        },

        image: {
            display: "flex",
            height: '700px',
            width: '411px',

            backgroundColor: 'purple',
            // backgroundImage: `url(${hoverUrl})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
        },

        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        textSocial: {
            margin: "0px 20px 0px",
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },

        buttonNavbar: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '120px',
            outline: 0
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButton: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: 12,
            color: '#FFF',
        },
        textsHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        titleHeader: {
            color: '#fff',
            fontSize: '52px',
            fontFamily: 'sans-serif',
            fontWeight: '600',
            margin: `${screenSize > 800 ? ('0px 20px 8px') : ('0px 20px 20px')}`,
            textAlign: 'center'
        },
        subtitleHeader: {
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            margin: "0px 40px 30px",
            textAlign: 'center'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },
        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },

    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            <div style={styles.container} id="scroller">
                <div style={{
                    display: "flex",
                    justifyContent: 'flex-start',
                    fontSize: 150,
                    color: '#000',
                    height: "436px",
                    backgroundImage: `url(${"https://i.postimg.cc/GpkqyN3V/MIA-JOREN.png"})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "right",
                    margin: "0px 0px 250px"
                }}>
                    <h1 style={styles.titleContainer}>MIA & JOREN</h1>
                </div>
                <div style={styles.imageContainer}>
                    <div style={styles.imagePadding}>
                        <img
                            src="https://i.postimg.cc/GtVydT2v/01.png"
                            //  height="1382"
                            height={screenSize > 800 ? ('829px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover" }}
                            alt="Image"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>I’m from a little town in Australia of 3,000 people and the third-born of triplets. But a quiet city and a shared birthday didn’t mean God didn’t have a unique, extraordinary plan for me. From a young age, people noticed that I had a gift of faith.</h3>
                    <h3 style={styles.textDescription}>I learned early how important it was to be convinced of the things of God. For example, at the age of 12, I’d written letters to God and said that one day I would write songs that went all over the world. I didn’t even have the skill set to do it, and I was one of the least popular kids growing up, but I didn’t discredit what God could do through me. Hebrews 11:1-3 in the Passion version says: “Now faith brings our hopes into reality and becomes the foundation needed to acquire the things we long for. It is all the evidence required to prove what is still unseen.”</h3>
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/sgFhLWbr/02.png"
                        //     height="457"
                        height={screenSize > 800 ? ('365px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("100%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>I lived for the unseen. It never occurred to me that God could be limited and He isn’t. He gave me the gift of song. I went from a tuba-playing, uncool kid to being part of a worship team with a local church in Australia for 10 years. And then, despite well-intended questions from friends, I followed the call of God and moved to the United States to write songs. My life up to that point had been a testament to God’s faithfulness. I knew how to proceed in faith and had seen God show up in every area. Well, in every area but one—I wasn’t yet married.</h3>
                    <h3 style={styles.textDescription}>I didn’t date much. The few guys I did date didn’t last. And the delay set me up for a season of disappointment that I minimized until God called me out. I blamed my circumstances, but honestly, my disappointment was directed towards God, and it was taking over. I had to let Him do a work in my heart to uproot it.</h3>
                    <h3 style={styles.textDescription}>Nothing changed circumstantially. But my response did. Excitement and anticipation for what God had in store for me marked my days rather than a feeling of lack.</h3>
                    <h3 style={styles.textDescription}>One day, I’m scrolling through Instagram, and Instagram knows I’m single, of course. This advertisement for an engagement ring pops up. And I was slightly annoyed, but I also really liked the ring. So, I took a screenshot because it was a really cool, vintage ring. Days later, I felt a bit lonely, and I went back to see how much the ring cost. But it was already sold!</h3>
                    <h3 style={styles.textDescription}>I’d resolved I would live a certain way no matter what. I wasn’t going to bait boys for attention. And I wasn’t going to try and make things happen. But while I’m holding out for God, I’m worshiping in church one day, and I asked Him if He could just let me know what He was doing while I waited. And here’s the beautiful thing—God told me, “It was in the mail,” and He gave me a vision.</h3>
                    <h3 style={styles.textDescription}>In the vision, I’m in this massive furniture storehouse, and I look up and God the Father is there, and He smiles at me. He told me I could pick out anything I wanted from these amazing one-off pieces of furniture. And I said, “I can’t even afford any of these!” But He smiled, gave me a gift card, and said, “You can pick anything you want and spend whatever you want.”</h3>
                    <h3 style={styles.textDescription}>So, I started looking around this furniture storehouse, and I saw something, and I knew it was for me! But someone got there before me, and I felt so sad. Every time I thought I’d found something, I felt no peace. And eventually, I returned to God, giving Him back the gift card. And I said, “If you don’t pick something out with me, I don’t think I can do this because I don’t know what’s best for me.” And He held me and reassured me. He said everything in that storehouse was good because He had created it. But if I wanted Him to pick for me, He’d custom-make something for me.</h3>
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/3RbyF02z/03.png"
                        //  height="457"
                        height={screenSize > 800 ? ('365px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("100%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>Here’s the thing about custom-made pieces, though. They take longer, and it’s going to mean you’re empty-handed for a little bit longer. He asked me if I was okay with that, and I told Him I was! And then the vision was over.</h3>
                    <h3 style={styles.textDescription}>So, God was going to custom-make me a husband! I held on to that for dear life. And then I go on this hunt, so to speak, looking for evidence of God at work. I bought men’s cologne and even lingerie to believe God would fulfill His promise. And I said to God, “You said it was in the mail.” One night, I even marched around the walls of my mail building. And I marched around it seven times. And I know the neighbors probably looked at me, wondering what in the world I was doing marching around at 1 a.m. But I did it anyway. And on the seventh march, nothing happened. But I didn’t need anything to happen. God saw it. And I believe it shifted something.</h3>
                    <h3 style={styles.textDescription}>Months passed, and I ended up going to Israel. What a great place to look for evidence, right? I was so excited to visit the Sea of Galilee because it’s where the waves of the sea haven’t forgotten how Jesus’s feet felt. So, I picked up a rock and wrote “marriage.” I went to the middle of the sea and prayed, thanking God that it was already done. And I threw the stone in the water and left that prayer in Israel.</h3>
                    <h3 style={styles.textDescription}>On the last day, we go to the field of Ruth and Boaz. Fields are significant to me because that’s my maiden name. And I started praying over the field. And I said, “God, I’m going to have a story that people know about and that You orchestrated. And I praise you in advance for it.” Before we left Israel, I went to a market looking for a ring. But after searching a bit, I realized all they had were men’s rings. Each one had a different Hebrew phrase, so I picked one up and asked the lady there what it said. And she read, “I am my Beloved’s, and my Beloved is for me.” And I for sure bought that ring!</h3>
                    <h3 style={styles.textDescription}>Half a year passed since God told me He was custom-making me a husband. And I couldn’t let sadness creep in during the waiting. I made it a habit of walking around my house, declaring things about my future husband. Maybe it sounds silly. But I was determined to align my words with what God had told me. And I wrote songs proclaiming God’s ability to do impossible things. My anthem was one of faith.</h3>
                    <h3 style={styles.textDescription}>After all the evidence gathering and constantly looking for the mark of God, I knew I needed to enter into a season of rest. Sometimes faith looks like rest too.</h3>
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/sxkvtF6c/04.png"
                        //  height="457"
                        height={screenSize > 800 ? ('365px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("100%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>And after that decision to rest, I received an email the following day. It said, “You don’t know me, but two years ago I saw you at your church, and you looked at me and smiled, and I’ve remembered it ever since. And I wondered if you would want to go on a date with me?”</h3>
                    <h3 style={styles.textDescription}>It was in the mail! Joren took me on our first date. And I found out that he was a city planner that worked with the government, a little taller than me with dark hair and a year older than me. And he has a very soft heart towards God and me.</h3>
                    <h3 style={styles.textDescription}>Six months later, Joren proposed! I hadn’t even told him what kind of ring I wanted because the one I liked, the one I’d taken a screenshot of, was already gone. But he opened the box, and can you even fathom it? It was the exact ring inside! To top things off, the men’s ring I’d purchased in Israel fit Joren and ended up being his wedding ring.</h3>
                    <h3 style={styles.textDescription}>What God orchestrates is more than what we can imagine. Remember how I’ve always thought that fields are significant because of my last name? Joren means ‘farmer,’ and a farmer can’t be a farmer without a field to take care of, can he?</h3>
                    <h3 style={styles.textDescription}>God doesn’t just do something good. God keeps doing something good. What felt like a delay was God bringing me the best. This story isn’t just about the custom-made husband God brought to me—it’s about faith.</h3>
                    <h3 style={styles.textDescription}>Faith made me feel uncomfortable at times. It made me do what others would consider foolish; I bought a ring, marched around a mailbox, threw a rock in the Sea of Galilee, and wrote song lyrics about things that hadn’t happened yet. But I trusted God. And I gathered evidence along the way, little tangible markers of who He is. Disappointment gave way to resolve, which gave way to see the goodness of God. God writes beautiful stories. And He keeps writing them, for you and me.</h3>
                </div>
                <div style={styles.imageContainer}>
                    {/* <img
                        src="https://i.postimg.cc/NGS9yTsH/Video.png"
                        //  height="650"
                        height={screenSize > 800 ? ('520px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("100%")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    /> */}
                    <iframe
                        height={screenSize > 800 ? ('520px') : ("170px")}
                        width={screenSize > 800 ? ("924") : ("80%")}
                        src="https://www.youtube.com/embed/-aDXPU8PBtg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>
                <div style={styles.singleFooter}>
                    <div style={styles.line} />
                    <div style={styles.textDate}>JUNE 20TH 2021</div>
                </div>
                <h2 style={styles.moreStoriesText}>MORE STORIES</h2>
                <div style={styles.storiesContainer}>
                    <Link to="/stories/holly"
                        onMouseOver={() => handleHover(true, "1")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "1" ? (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/Bv9PbrcK/Holly-Hover.png"
                                        src="https://i.postimg.cc/xTqXCCWy/Holly-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/X7Fd25d6/Holly.png"
                                        src="https://i.postimg.cc/PrLqH7wS/Holly.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/sarah"
                        onMouseOver={() => handleHover(true, "2")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "2" ? (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/W41zdsqt/Sarah-Hover.png"
                                        src="https://i.postimg.cc/Jz50YkJ8/Sarah-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/JnnzSfFB/Sarah.png"
                                        src="https://i.postimg.cc/50T2QqcB/Sarah.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/baylor"
                        onMouseOver={() => handleHover(true, "4")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "4" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/L4zCsStz/Baylor-Hover.png"
                                        src="https://i.postimg.cc/R00SPb41/Baylor-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/qMXghx2q/Baylor.png"
                                        src="https://i.postimg.cc/dV5vTmrh/Baylor.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                </div>
            </div>
        </>
    );
};
