import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Story6 from "../components/Story-06";

function Storty02Container() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hoverUrl, setHoverUrl] = useState("");

    const handleHover = (active, url) => {
        if (hoverUrl == "") {
            //  console.log('cambie a activo')
            setHoverUrl(url)
        }
    }
    const handleHoverOut = (active, url) => {
        if (hoverUrl != "") {
            //   console.log('desactivo')
            setHoverUrl("")
        }
    }

    return (
        <>
            <Helmet>
                <title>Alyssa's Story | But God Book </title>
                <meta
                    name="description"
                    content="I met the Lord at the ceiling of the three-story operating suite of the teaching hospital. He 
spoke to me and told me that “the prayers of the saints had given me a choice” to come with him 
or to stay. "
                />
            </Helmet>
            <Story6
                screenSize={windowWidth}
                handleHover={handleHover}
                handleHoverOut={handleHoverOut}
                hoverUrl={hoverUrl}
            />
        </>
    );
}

export default Storty02Container;