import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Contact from "../components/Contact";

function ContactContainer() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    return (
        <>
            <Helmet>
                <title>Contact Us | But God Book</title>
                <meta
                    name="description"
                    content="If you have any questions, concerns or issues, we're here to help. Get in touch with the But God team here."
                />
            </Helmet>
            <Contact
                screenSize={windowWidth}
            />
        </>

    );
}

export default ContactContainer;