import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Story2 from "../components/Story-02";

function Storty02Container() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hoverUrl, setHoverUrl] = useState("");

    const handleHover = (active, url) => {
        if (hoverUrl == "") {
            //  console.log('cambie a activo')
            setHoverUrl(url)
        }
    }
    const handleHoverOut = (active, url) => {
        if (hoverUrl != "") {
            //   console.log('desactivo')
            setHoverUrl("")
        }
    }

    return (
        <>
            <Helmet>
                <title>Sarah's Story | But God Book </title>
                <meta
                    name="description"
                    content="Sarah was insecure for most of her life, with a debilitating diagnosis that turned her life upside down. But God intervened and set her on a new path. Read more of her story here."
                />
            </Helmet>
            <Story2
                screenSize={windowWidth}
                handleHover={handleHover}
                handleHoverOut={handleHoverOut}
                hoverUrl={hoverUrl}
            />
        </>
    );
}

export default Storty02Container;