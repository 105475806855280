/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";
//import "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import Navbar from "./NavbarTransparent";

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleHover, handleHoverOut, hoverUrl }) => {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            // backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            backgroundColor: '#000',
            height: '100vh',
            overflow: `auto`,
        },
        titleContainer: {
            display: "flex",
            //    alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   zIndex: 100,
            //    height: '100px',
            fontSize: `${screenSize > 800 ? ("180px") : ("70px")}`,
            color: '#fff',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 40vw 0px 45px') : ('0px 40px')} `,
            margin: `${screenSize > 800 ? ("25vh 0px 0vh") : ("30vh 0px 5vh")} `,
            fontFamily: "BigCaslon",
            fontWeight: 500,
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
            position: "relative",
            zIndex: '100',
            top: `${screenSize > 800 ? ('70px') : ('140px')} `,
            left: 0,
            // right: 0,
        },
        textContainer: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: `${screenSize > 800 ? ("20px 0px") : ("20px 0px")} `,
        },
        textDescription: {
            margin: `${screenSize > 800 ? ("30px 25vw") : ("20px 20vw")} `,
            fontSize: 20,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        textDescriptionbig: {
            margin: `${screenSize > 800 ? ("30px 25vw") : ("20px 20vw")} `,
            fontSize: `${screenSize > 800 ? ("70px") : ("30px")}`,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: `${screenSize > 800 ? ("70px") : ("30px")}`,
            marginTop: 50,
            marginBottom: 50,
            fontWeight: 500
        },
        imageContainer: {
            display: "flex",
            gap: '20px',
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: "50px 0px",
            flexWrap: `${screenSize > 800 ? ("nowrap") : ("wrap")}`,

        },
        imagePadding: {
            padding: '10px',
            // backgroundColor: '#000',
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            width: `${screenSize > 800 ? (<></>) : ("80%")} `,
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            fontSize: 16,
            color: '#000',
            width: '391px',
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: "100px 0px 130px",
            // backgroundColor: 'red',
            borderRadius: '50px',
            border: '1px solid #000',
            // fontWeight: 700,
            // fontFamily: "Nimbus",
            // lineHeight: '19.2px',
        },
        footer: {
            display: "flex",
            margin: "0px 60px 150px",
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooterLeft: {

        },
        singleFooter: {
            display: "flex",
            flexDirection: 'column',
            flex: 1,
            height: '100%',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: `${screenSize > 800 ? ("0px 0px 0px 60px") : ("0px 20px")}`,
            margin: '50px 0px 0px'
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: `${screenSize > 800 ? ("921px") : ("80vw")}`,
            height: "2px",
            alignSelf: 'flex-start',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            backgroundColor: '#000'
        },
        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        textSocial: {
            margin: "0px 20px 0px",
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        textDate: {
            display: "flex",
            margin: "20px 0px 0px",
            fontSize: 12,
            color: '#000',
            lineHeight: "14px",
        },
        moreStoriesText: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            margin: "100px 0px 83px",
            margin: `${screenSize > 800 ? ("100px 0px 83px") : ("70px 0px 30px")}`,
            //margin: "30px 30vw",
            fontSize: 12,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "14px",
            fontWeight: 700
        },
        storiesContainer: {
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            // fontSize: 20,
            // color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ("0px 50px") : ("0px 5px")}`,
            margin: `${screenSize > 800 ? ("0px 0px 150px") : ("0px 0px 100px")}`,
            // backgroundColor: 'yellow'
        },
        photo: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            //  margin: '0px 10px'
            margin: '0px'
        },

        image: {
            display: "flex",
            height: '700px',
            width: '411px',

            backgroundColor: 'purple',
            // backgroundImage: `url(${hoverUrl})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
        },


        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },

        buttonNavbar: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '120px',
            outline: 0
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButton: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: 12,
            color: '#FFF',
        },
        textsHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        titleHeader: {
            color: '#fff',
            fontSize: '52px',
            fontFamily: 'sans-serif',
            fontWeight: '600',
            margin: `${screenSize > 800 ? ('0px 20px 8px') : ('0px 20px 20px')}`,
            textAlign: 'center'
        },
        subtitleHeader: {
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            margin: "0px 40px 30px",
            textAlign: 'center'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },
        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },
    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            <div style={styles.container} id="scroller">
                <div style={{
                    display: "flex",
                    justifyContent: 'flex-start',
                    fontSize: 150,
                    color: '#000',
                    height: "436px",
                    // backgroundImage: `url(${"https://i.postimg.cc/Y9c2rNfr/SARAH-GERALD.jpg"})`,
                    backgroundColor: '#000',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "right",
                    margin: "0px 0px 150px"
                }}>
                    <h1 style={styles.titleContainer}>JESS</h1>
                </div>
                <div style={styles.imageContainer}>
                    <div style={styles.imagePadding}>
                        <img
                            src="https://i.postimg.cc/rFSmN3bR/jess1.jpg"
                            //    height="1382"
                            height={screenSize > 800 ? ('829px') : (<></>)}
                            width={screenSize > 800 ? (<></>) : ("100%")}
                            style={{ objectFit: "cover" }}
                            alt="Image"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>I always imagined getting married at a young age. As early as five years old, I could
                        picture it, envision it, see it…all the details so clearly. I’d practice my wedding dress
                        sketches and scribble various last names in the margin, just to see how they’d look with
                        my first name. I’d write my future husband letters and hide them away for a later date. <br /> <br />

                        When I was 11, I felt the Lord speak to me to save not only my body for my husband,
                        but also my heart. As a result, I chose not to consider dating until I was at an age to
                        consider marriage. <br /> <br /> <br />

                        Over the next 17 years, opportunities came, but I never felt God’s peace. After years of
                        waiting and getting to know guys in my community in group settings, it was always
                        obvious…this just wasn’t it.<br /> <br />
                        It seemed my girlfriends found their husbands so easily! Wedding showers, followed by
                        baby news, gender reveal parties and baby showers. As genuinely happy as I was to
                        celebrate meaningful life events with my friends, there I was…one of the last standing.<br /> <br />

                        By the time I was 28, life was full and fulfilling. I had pursued my passions and traveled
                        the world, but still had never been on a date! <br /> <br />
                    </h3>

                </div>
                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/nz8Yg6Fb/jess2.jpg"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />

                </div>
                <div style={styles.textContainer}>

                    <h3 style={styles.textDescription}>
                        I felt led to move to Nashville after facing some unexpected health challenges. After two
                        years in a new city and still with no prospects, the journey to find “the one” felt more and
                        more intense. To loosen the grip and relinquish control, I told the Lord that I was open to
                        however He chose to bring the right person into my life. Looking back, He really took me
                        up on that prayer!
                    </h3>
                    <h3 style={styles.textDescription}>
                        I decided to download a few dating apps and started going on dates. I had a knack for
                        making cool connections online—from finding roommates on Facebook, to making
                        friends on Instagram—even locating rental properties through social media. So why not
                        dating?! The online dating journey was full of ups and downs. I met some nice people,
                        but it was always obvious…this just wasn’t it.

                    </h3>
                    <h3 style={styles.textDescription}>
                        By early 2021, I felt that I came to the end of my hope rope. One of my best friends got
                        engaged. I was excited for her, of course, but she wasn’t necessarily looking and “the
                        one” just seemed to fall into her lap! God had been so faithful in every area of my life,
                        time and time again, yet this one area that I so desperately longed for felt barren. <br /> <br /> That summer, I told her,


                    </h3>


                    {/* QUOTE HERE */}
                    <h3 style={styles.textDescriptionbig}>
                        “Look…I really want us to pray that my husband will come to your wedding with me.”
                    </h3>

                </div>

                <div style={styles.textContainer}>

                    <h3 style={styles.textDescription}>WHAT?! Looking back, I have no idea where that kind of bold
                        faith came from!
                        <br /> <br />
                        Less than two weeks later, Christian and I connected. I could immediately tell he was
                        different. He lived out of town but made plans to fly in to pick me up for our first date.
                        After that, I told my parents, “I would be surprised if he’s not the one.” I always prayed
                        the right person would be just as obvious as the not-so-right ones, and God answered
                        that prayer…this is it.
                        <br />
                        <br />
                        Christian kept taking jobs that would bring him to Nashville and we would make any
                        excuse to see each other. In early November, he came to town to work a worship event
                        I was attending. Little did I know at the time that he spotted me in the crowd and felt an
                        overwhelming peace that I would be his wife. Little did he know that I felt the same. With
                        my friend’s wedding only two weeks away and no plan to see each other next…this was
                        my last shot to invite him. With zero hesitation, he said yes!
                        <br />
                        <br />
                        Christian came to Dallas for my friend’s wedding and met my family, and what felt like
                        everyone else I’d ever known. It’s amazing to think that none of my friends had ever
                        seen me with someone! I then met his family over the holidays. Before long, our parents
                        also met and became great friends as we continued to date. In hindsight, I realize I was
                        not only waiting for the right guy, but also for the right in-laws…such a special bonus!<br /> <br />
                        After 11 months, we went from long distance to very short distance…literally to around
                        the corner. Christian moved to Nashville and found a place down the street from me.
                        With the way the housing market was, it was miraculous! We had the best time living
                        near each other, experiencing everyday life together, and taking neighborhood walks.


                        <br />
                        <br />

                        AOn May 18, 2023, Christian proposed during one of those neighborhood walks, and we
                        married four months later!
                        Before I met Christian, there were days when I wasn’t sure God cared about my heart’s
                        desires. Looking back, I now realize He was weaving so many intricate details into our
                        one-of-a-kind story that it would take a lifetime to share it all. And that’s what I plan on
                        doing!

                        <br />
                        <br />

                        It’s easy to become so focused on what we don’t have that we miss out on what’s right
                        in front of us. If you’re in a season of waiting, don’t let the ache overtake you. Waiting is
                        active. Chase your dreams, travel, celebrate your friends, and pursue God’s calling on
                        your life!

                        <br />
                        <br />
                        I don’t understand God’s timing, but after getting married in my early 30s, I wouldn’t
                        trade the wait for anything. He can do in a moment what would otherwise take a lifetime!
                        He is the God of Redemption.

                        <br />
                        <br />
                        Finding “the one” isn't the end of my story. Instead, it’s the beginning of a new chapter
                        and a continuation of what God has already been doing in two individual lives, now
                        united in Him.

                        <br />
                        <br />
                        Being single doesn't mean you're incomplete, and a spouse isn't the ultimate prize. God
                        Himself is the prize, and His faithfulness is a testament to His promise-keeping work in
                        our lives!
                        <br />
                        <br />



                    </h3>
                </div>

                <div style={styles.imageContainer}>
                    <img
                        src="https://i.postimg.cc/Nj56MgLv/jess3.jpg"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />
                    <img
                        src="https://i.postimg.cc/gcLrnnHD/jess4.jpg"
                        //   height="1030"
                        height={screenSize > 800 ? ('400px') : (<></>)}
                        width={screenSize > 800 ? (<></>) : ("320px")}
                        style={{ objectFit: "cover" }}
                        alt="Image"
                        loading="lazy"
                    />

                </div>
                <div style={styles.singleFooter}>
                    <div style={styles.line} />
                    <div style={styles.textDate}>JUNE 20TH 2021</div>
                </div>
                <h2 style={styles.moreStoriesText}>MORE STORIES</h2>
                <div style={styles.storiesContainer}>
                    <Link to="/stories/holly"
                        onMouseOver={() => handleHover(true, "1")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "1" ? (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/Bv9PbrcK/Holly-Hover.png"
                                        src="https://i.postimg.cc/xTqXCCWy/Holly-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/X7Fd25d6/Holly.png"
                                        src="https://i.postimg.cc/PrLqH7wS/Holly.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/mia-and-joren"
                        onMouseOver={() => handleHover(true, "3")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "3" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/VL0Nt7NB/Mia-Joren-Hover.png"
                                        src="https://i.postimg.cc/7PRxKNW0/Mia-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/vBkxh8mm/Mya-Joren.png"
                                        src="https://i.postimg.cc/LsvSLkPq/Mia.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/stories/baylor"
                        onMouseOver={() => handleHover(true, "4")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "4" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/L4zCsStz/Baylor-Hover.png"
                                        src="https://i.postimg.cc/R00SPb41/Baylor-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/qMXghx2q/Baylor.png"
                                        src="https://i.postimg.cc/dV5vTmrh/Baylor.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                </div>
            </div>
        </>
    );
};
