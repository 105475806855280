import React from "react";
import { Link } from "react-router-dom";
//import "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import Navbar from "./NavbarTransparent";

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleHover, handleHoverOut, hoverUrl }) => {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            height: '100vh',
            overflow: `auto`,
        },
        titleContainer: {
            display: "flex",
            //    alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   zIndex: 100,
            //    height: '100px',
            fontSize: `${screenSize > 800 ? ("180px") : ("50px")}`,
            color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 40vw 0px 45px') : ('0px 40px')} `,
            margin: `${screenSize > 800 ? ("25vh 0px 0vh") : ("30vh 0px 5vh")} `,
            fontFamily: "BigCaslon",
            fontWeight: 500,
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
            position: "relative",
            zIndex: '100',
            top: `${screenSize > 800 ? ('70px') : ('140px')} `,
            left: 0,
            // right: 0,
        },
        textContainer: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            color: '#000',
            width: '100vw',
            margin: `${screenSize > 800 ? ("20px 0px") : ("20px 0px")} `,
        },
        textDescription: {
            margin: `${screenSize > 800 ? ("30px 33vw") : ("20px 20vw")} `,
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        imageContainer: {
            display: "flex",
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: "50px 0px"
        },
        imagePadding: {
            padding: '10px',
            backgroundColor: '#000',
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            width: `${screenSize > 800 ? (<></>) : ("80%")} `,
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            fontSize: 16,
            color: '#000',
            width: '391px',
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: "100px 0px 130px",
            // backgroundColor: 'red',
            borderRadius: '50px',
            border: '1px solid #000',
            // fontWeight: 700,
            // fontFamily: "Nimbus",
            // lineHeight: '19.2px',
        },
        footer: {
            display: "flex",
            margin: "0px 60px 150px",
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooterLeft: {

        },
        singleFooter: {
            display: "flex",
            flexDirection: 'column',
            flex: 1,
            height: '100%',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: `${screenSize > 800 ? ("0px 0px 0px 60px") : ("0px 20px")}`,
            margin: '50px 0px 0px'
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: `${screenSize > 800 ? ("921px") : ("80vw")}`,
            height: "2px",
            alignSelf: 'flex-start',
            alignItems: "flex-start",
            justifyContent: "flex-start",
            backgroundColor: '#000'
        },
        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        textSocial: {
            margin: "0px 20px 0px",
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        textDate: {
            display: "flex",
            margin: "20px 0px 0px",
            fontSize: 12,
            color: '#000',
            lineHeight: "14px",
        },
        moreStoriesText: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            margin: "100px 0px 83px",
            margin: `${screenSize > 800 ? ("100px 0px 83px") : ("70px 0px 30px")}`,
            //margin: "30px 30vw",
            fontSize: 12,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: "14px",
            fontWeight: 700
        },
        storiesContainer: {
            display: "flex",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            // fontSize: 20,
            // color: '#000',
            width: '100vw',
            padding: `${screenSize > 800 ? ("0px 50px") : ("0px 5px")}`,
            margin: `${screenSize > 800 ? ("0px 0px 150px") : ("0px 0px 100px")}`,
            // backgroundColor: 'yellow'
        },
        photo: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            //  margin: '0px 10px'
            margin: '0px'
        },

        image: {
            display: "flex",
            height: '700px',
            width: '411px',

            backgroundColor: 'purple',
            // backgroundImage: `url(${hoverUrl})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
        },


        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },

        buttonNavbar: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '120px',
            outline: 0
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButton: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: 12,
            color: '#FFF',
        },
        textsHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        titleHeader: {
            color: '#fff',
            fontSize: '52px',
            fontFamily: 'sans-serif',
            fontWeight: '600',
            margin: `${screenSize > 800 ? ('0px 20px 8px') : ('0px 20px 20px')}`,
            textAlign: 'center'
        },
        subtitleHeader: {
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            margin: "0px 40px 30px",
            textAlign: 'center'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },
        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },
        videoContainer: {
            // display: "flex",
            // alignItem: 'center',
            // justifyContent: 'center',
            // height: `${screenSize > 800 ? ('650px') : ('')} `,
            // width: '100%',
            // padding: `${screenSize > 800 ? ('20px') : ('40px')} `,
            // backgroundColor: 'white',
            // margin: '100px 0px 100px'
            display: "flex",
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: `${screenSize > 800 ? ("50px 0px") : ("50px 0px")} `,
        },
        videoPadding: {
            // padding: '10px',
            // backgroundColor: '#000',
            padding: '10px',
            backgroundColor: '#000',
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            width: `${screenSize > 800 ? (<></>) : ("80%")} `,
        },
    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            <div style={styles.container} id="scroller">
                <div style={{
                    display: "flex",
                    justifyContent: 'flex-start',
                    fontSize: 150,
                    color: '#000',
                    height: "436px",
                    backgroundImage: `url(${"https://i.postimg.cc/X7Z9TQrD/COURTNEY.png"})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "right",
                    margin: "0px 0px 150px"
                }}>
                    <h1 style={styles.titleContainer}>COURTNEY</h1>
                </div>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>“On the way to the hospital, I sat in the back of the ambulance with my son and couldn't comprehend what I had seen. I could not understand how I held my baby Leo when he was dead, but then I saw him return to life. There is no place in my mind to fathom this. I have only grateful awe of God and His resurrection power”</h3>
                </div>
                <div style={styles.videoContainer}>
                    <div style={styles.videoPadding}>
                        <iframe
                            height={screenSize > 800 ? ('520px') : ("170px")}
                            width={screenSize > 800 ? ("924") : ("100%")}
                            src="https://www.youtube.com/embed/xoSredm08hw?si=nexFEeEr2vlZ3UD4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                        </iframe>
                    </div>
                </div>
                <div style={styles.singleFooter}>
                    <div style={styles.line} />
                    <div style={styles.textDate}>JUNE 20TH 2021</div>
                </div>
                <h2 style={styles.moreStoriesText}>MORE STORIES</h2>
                <div style={styles.storiesContainer}>
                    <Link to="/videos/kathryn"
                        onMouseOver={() => handleHover(true, "1")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "1" ? (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/Bv9PbrcK/Holly-Hover.png"
                                        src="https://i.postimg.cc/9MSBsSY1/Kathryn-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //  src="https://i.postimg.cc/X7Fd25d6/Holly.png"
                                        src="https://i.postimg.cc/qvLxpgGN/Kathryn.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                    <Link to="/videos/cindy"
                        onMouseOver={() => handleHover(true, "3")}
                        onMouseOut={() => handleHoverOut(false, "")}
                    >
                        <>
                            {hoverUrl == "3" ? (
                                <div style={styles.photo}>
                                    <img
                                        //       src="https://i.postimg.cc/VL0Nt7NB/Mia-Joren-Hover.png"
                                        src="https://i.postimg.cc/fymYpbLT/Cindy-Hover.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            ) : (
                                <div style={styles.photo}>
                                    <img
                                        //    src="https://i.postimg.cc/vBkxh8mm/Mya-Joren.png"
                                        src="https://i.postimg.cc/8zKfyvHm/Cindy.png"
                                        //  width={screenSize > 800 ? ("650px") : ("100%")}
                                        width={screenSize > 800 ? ("100%") : ("100%")}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </>
                    </Link>
                </div>
            </div>
        </>
    );
};
