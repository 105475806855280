import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Hannah from "../components/Hannah";
import { useHistory } from "react-router-dom";

function HannahContainer() {

    const history = useHistory();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleRedirect = (e) => {
        if (e == "exhibition") {
            history.push("/exhibition");
        } else if (e == "stories") {
            history.push("/stories");
        } else if (e == "about") {
            history.push("/about");
        }
    };

    return (
        <>
            <Helmet>
                <title>About Hannah Corwin | But God Book</title>
                <meta
                    name="description"
                    content="Hannah Corwin is a Nashville-based portrait photographer, passionate about telling compelling stories with every image. But God Book is her debut solo project."
                />
            </Helmet>
            <Hannah
                screenSize={windowWidth}
                handleRedirect={handleRedirect}
            />
        </>

    );
}

export default HannahContainer;