import React from "react";
import { Link } from "react-router-dom";
//import "node_modules/video-react/dist/video-react.css";
// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import Navbar from "../components/NavbarBlack";
// import HoverImage from "react-hover-image";

let year = new Date().getFullYear()

export default ({ screenSize, handleHover, hoverUrl, darkModeState, handleHoverOut, handleSubmit, language, page, firstName, lastName, numberPhone, mail, message, messageSent }) => {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#000',
            height: '100vh',
            overflow: `auto`,
        },
        titleContainer: {
            display: "flex",
            //    alignItems: 'flex-start',
            justifyContent: 'flex-start',
            //   zIndex: 100,
            //    height: '100px',
            color: '#fff',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 23vw 0px 45px') : ('0px 40px 0px 40px')} `,
            margin: `${screenSize > 800 ? ("25vh 0px 10vh") : ("30vh 0px 10vh")} `,
            fontSize: `${screenSize > 800 ? ("180px") : ("70px")} `,
            fontFamily: "BigCaslon",
            fontWeight: 500,
            lineHeight: `${screenSize > 800 ? ('140px') : ('70px')} `,
        },
        textContainer: {
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            color: '#fff',
            width: '100vw',
            margin: `${screenSize > 800 ? ("20px 0px") : ("20px 0px")} `,
        },
        textDescription: {
            margin: `${screenSize > 800 ? ("0px 33vw 30px") : ("0px 20vw")} `,
            fontSize: 16,
            color: '#fff',
            fontFamily: "BigCaslon",
            lineHeight: "24px",
            fontWeight: 500
        },
        storiesContainer: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ('row') : ('column')} `,
            //alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 40px') : ('0px 30px')} `,
            margin: "60px 0px 0px",
            //     backgroundColor: 'yellow'
        },
        storiesContainerSideLeft: {
            display: "flex",
            flex: 1,
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
            fontSize: 20,
            color: '#000',
            //   height: '4000px',
            padding: '0px',

            //     backgroundColor: 'green',
        },
        storiesContainerSideRight: {
            display: "flex",
            flex: 1,
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            fontSize: 20,
            color: '#000',
            //   height: '4000px',
            padding: '0px',
            margin: `${screenSize > 800 ? ('160px 0px 0px') : ('0px')} `,
            //     backgroundColor: 'green',
        },
        videoContainer: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            //   height: '451px',
            //   width: '686px',
            //     height: `${screenSize > 800 ? ('400px') : ('')} `,
            // width: '100%',
            //    padding: '20px',
            padding: '0px',
            backgroundColor: 'white',
            margin: `${screenSize > 800 ? ('40px') : ('20px')} `,
        },
        video: {
            display: "flex",
            // height: '100px',
            // width: '100px',
        },
        thanksTitle: {
            display: "flex",
            justifyContent: 'flex-start',
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 60px') : ('0px 30px')} `,
            margin: "60px 0px 0px",
            color: '#fff',
            fontSize: `${screenSize > 800 ? ("44px") : ("30px")} `,
            fontFamily: "BigCaslon",
            fontWeight: 400,
            lineHeight: `${screenSize > 800 ? ('48px') : ('20px')} `,
        },
        thanksContainer: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ('row') : ('column')} `,
            //alignItems: 'center',
            justifyContent: `${screenSize > 800 ? ('space-between') : ('center')} `,
            width: '100vw',
            padding: `${screenSize > 800 ? ('0px 60px') : ('0px 30px')} `,
            margin: "30px 0px 100px",
            //  backgroundColor: 'yellow'
        },
        thanksContainerSideLeft: {
            display: "flex",
            flex: 1,
            flexDirection: 'column',
            alignItems: `${screenSize > 800 ? ('flex-start') : ('center')} `,
            justifyContent: "flex-start",
            fontSize: 20,
            color: '#000',
            //   height: '4000px',
            padding: '0px',

            //     backgroundColor: 'green',
        },
        thanksContainerSideRight: {
            display: "flex",
            flex: 1,
            flexDirection: 'column',
            alignItems: `${screenSize > 800 ? ('flex-end') : ('center')} `,
            //  justifyContent: 'flex-end',
            fontSize: 20,
            color: '#000',
            //   height: '4000px',
            padding: '0px',
            //    margin: "160px 0px 0px",
            //     backgroundColor: 'green',
        },
        singleThankContainer: {
            display: "flex",
            alignItems: 'flex-start',
            flexDirection: 'column',
            //   justifyContent: 'flex-start',
            //   height: '451px',
            width: '333px',
            //    height: `${screenSize > 800 ? ('400px') : ('')} `,
            // width: '100%',
            //    padding: '20px',
            //     backgroundColor: 'white',
            margin: '20px 0px 0px'
        },
        singleThankTitleLeft: {
            display: "flex",
            fontFamily: "BigCaslon",
            fontWeight: 700,
            fontSize: `${screenSize > 800 ? ("16px") : ("12px")} `,
            lineHeight: `${screenSize > 800 ? ('24px') : ('20px')} `,
            color: '#fff'
        },
        singleThankDescriptionLeft: {
            display: "flex",
            fontFamily: "BigCaslon",
            fontWeight: 400,
            fontSize: `${screenSize > 800 ? ("16px") : ("12px")} `,
            lineHeight: `${screenSize > 800 ? ('24px') : ('20px')} `,
            textAlign: 'justify',
            color: '#fff'
        },
        singleThankTitleRight: {
            display: "flex",
            alignSelf: `${screenSize > 800 ? ("flex-end") : ("flex-start")} `,
            fontFamily: "BigCaslon",
            fontWeight: 700,
            fontSize: `${screenSize > 800 ? ("16px") : ("12px")} `,
            lineHeight: `${screenSize > 800 ? ('24px') : ('20px')} `,
            textAlign: `${screenSize > 800 ? ("right") : ("left")} `,
            color: '#fff'
        },
        singleThankDescriptionRight: {
            display: "flex",
            fontFamily: "BigCaslon",
            fontWeight: 400,
            fontSize: `${screenSize > 800 ? ("16px") : ("12px")} `,
            lineHeight: `${screenSize > 800 ? ('24px') : ('20px')} `,
            textAlign: 'justify',
            color: '#fff'
        },


        photoDetail: {
            display: "flex",
            alignItem: 'center',
            justifyContent: 'center',
            height: '740px',
            width: '451px',
            backgroundImage: `url(${hoverUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            margin: '0px 0px 20px'
        },

        photoDetailInfo: {
            display: "flex",
            flexDirection: 'column',
            alignItem: 'center',
            justifyContent: 'center',
            height: '740px',
            width: '451px',
            backgroundColor: 'white',
            margin: '0px 0px 20px',
            backgroundColor: 'rgba(255, 255, 255, 60%)'
        },

        photoDetailInfoTitle: {

            display: "flex",
            flexDirection: 'column',
            alignItem: 'center',
            justifyContent: 'center',
            height: '740px',
            width: '451px',
            backgroundColor: 'white',
            margin: '0px 0px 20px',
            //   backgroundColor: 'rgba(255, 255, 255, 60%)'

            backgroundColor: 'yellow'

            // display: "flex",
            // flex: 1
        },
        photoDetailInfoName: {
            display: "flex",
            flex: 1
        },
        photoDetailInfoButton: {
            display: "flex",
            flex: 1
        },
        imageContainer: {
            display: "flex",
            // flex: 1,
            //    alignItems: "center",
            justifyContent: "center",
            margin: "100px 0px"
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignSelf: 'center',
            width: `${screenSize > 800 ? ('391px') : ("70vw")} `,
            padding: '8px 25px',
            // padding: '0px 50vw 0px 45px',
            margin: `${screenSize > 800 ? ("0px 0px 130px") : ("0px 40px 70px")} `,
            backgroundColor: '#000',
            borderRadius: '50px',
            border: '1.5px solid #fff',
        },
        textbutton: {
            fontSize: 16,
            fontFamily: "BigCaslon",
            color: '#fff',
            display: "flex",
            alignSelf: 'center',
            margin: 0
        },
        footer: {
            display: "flex",
            margin: `${screenSize > 800 ? ("0px 60px 100px") : ("0px 20px 100px")} `,
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooter: {
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "center",
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: "216px",
            height: "2px",
            // alignSelf: 'flex-end',
            // justifyContent: 'flex-end',
            //  left: "648px",
            //    top: "930px",
            backgroundColor: '#000'
        },
        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        textSocial: {
            margin: `${screenSize > 800 ? ("0px 20px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#fff',
            fontFamily: "BigCaslon",
            outline: 0,
            backgroundColor: 'transparent',
            border: 'none'
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        titleNavbar: {
            //  marginBottom: `${ screenSize > 800 ? ('5px') : ("10px") } `,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")} `,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },


    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            <div style={styles.container} id="scroller">
                <div style={{ height: 41 }} />
                <h1 style={styles.titleContainer}>BEHIND THE SCENES</h1>
                <div style={styles.textContainer}>
                    <h3 style={styles.textDescription}>Behind The Scenes with BUT GOD. THANK YOU to everyone who has CONTRIBUTED and PARTICIPATED in this project. </h3>
                </div>
                <div style={styles.storiesContainer}>
                    <div style={styles.storiesContainerSideLeft}>
                        {/* <div style={styles.videoContainer}>
                            <ReactPlayer
                                className='react-player'
                                url='/assets/videos/MIA & JOREN _LINERS_V1.mp4'
                                style={styles.video}
                                height={screenSize > 800 ? ("") : ("170px")}
                                controls={true}
                            />
                        </div> */}
                        {/* <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/nVKPN9jY/behind-the-scenes-02.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/4y4bDdqB/behind-the-scenes-01.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div> */}
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/s2pY17RT/behind-the-scenes-03.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/4nk4K7G1/behind-the-scenes-05.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/TYggtnKf/behind-the-scenes-07.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/JzQJLm3F/behind-the-scenes-09.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/j5DJPfVV/behind-the-scenes-11.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/qBC372Xy/behind-the-scenes-13.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/bvJGfnwG/behind-the-scenes-15.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/zvm3hPSW/behind-the-scenes-16.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                    </div>
                    <div style={styles.storiesContainerSideRight}>
                        {/* <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/4y4bDdqB/behind-the-scenes-01.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/nVKPN9jY/behind-the-scenes-02.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div> */}
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/4NPN43Mp/behind-the-scenes-04.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/zXz8QrBv/behind-the-scenes-06.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/cHBP7vfm/behind-the-scenes-08.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/0NdVFLr5/behind-the-scenes-10.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/d3Dn736D/behind-the-scenes-12.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>
                        <div style={styles.videoContainer}>
                            <img
                                src="https://i.postimg.cc/05HcTG5S/behind-the-scenes-14.png"
                                // height={screenSize > 800 ? ("1066px") : ("100%")}
                                // width={screenSize > 800 ? (<></>) : ("100%")}
                                //    height={screenSize > 800 ? ("100%") : ("100%")}
                                width={screenSize > 800 ? ("600px") : ("100%")}
                            //  alt="Image"
                            />
                        </div>

                    </div>
                </div>



                <div style={styles.thanksTitle}>THANK YOU</div>
                <div style={styles.thanksContainer}>
                    <div style={styles.thanksContainerSideLeft}>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleLeft}>Every person in this book</div>
                            <div style={styles.singleThankDescriptionLeft}>Thank you for your bravery, your courage and boldness to talk about areas so personal, in order to see others in freedom. This book is for you.
                            </div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleLeft}>Max Corwin</div>
                            <div style={styles.singleThankDescriptionLeft}>Max, my darling husband. This is our first of many babies (literary babies). Thank you for your patience, your grace, your consistent kindness. Your unwavering faith, love, support and holding my arms up when I felt like I couldn’t. This book would not be what it is without your love, patience and support.
                                I love you. </div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleLeft}>Trey & April Cushman</div>
                            <div style={styles.singleThankDescriptionLeft}>This project would not exist without you. Me and Max are forever grateful that you have believed in us, championed, supported, prayed and got in the trenches with us. We are forever grateful.</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleLeft}>Shelley Griffin</div>
                            <div style={styles.singleThankDescriptionLeft}>Shelley, your words, edits, kindness, patience have taken this book to a level of excellence that I can't even fathom. Thank you for your prayers, labor and diligence over this and these stories, these stories that hold a heavenly weight.</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleLeft}>Alisha Pagliarulo</div>
                            <div style={styles.singleThankDescriptionLeft}>Lish, where do I start...You have carried this with me, for every spreadsheet, phone call, text, encouragement. These are sacrifices that we can only see on the other side of eternity. Thank you for everything you’ve labored into this book. Your level of excellence is unmatched and I’m forever grateful.</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleLeft}>Twila Tharan</div>
                            <div style={styles.singleThankDescriptionLeft}>Twi! Thank you for everything you did to make every person in this book look and feel amazing! You pulled fifty looks in under three weeks, while having a five week old baby. You’re a gift to so many and so thankful for you and what you always bring!</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleLeft}>Marz Collins</div>
                            <div style={styles.singleThankDescriptionLeft}>Marz you have a warmth and ability to make everyone feel at ease. Not to mention you make everyone look and feel amazing! Thank you for pouring your gifts into this book.</div>
                        </div>

                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleLeft}>Cass & Davis Jackson</div>
                            <div style={styles.singleThankDescriptionLeft}>Cass and Davis, thank you. Thank you for the endless hours of editing, filming, prepping, texting, and bringing this video vision to life. Thank you for being the biggest champions of this project. I’m your biggest cheerleaders!</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleLeft}>Zoe Zimmitti</div>
                            <div style={styles.singleThankDescriptionLeft}>Zoe, thank you for everything you did in supporting Twila with this project. Thank you for bouncing between set and wardrobe, for running clothes, for pivoting when we changed ideas.</div>
                        </div>
                        {/* <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleLeft}></div>
                            <div style={styles.singleThankDescriptionLeft}></div>
                        </div> */}
                    </div>
                    <div style={styles.thanksContainerSideRight}>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleRight}>Liz Keck</div>
                            <div style={styles.singleThankDescriptionRight}>Liz! Your hospitality giftings are like no one I’ve ever met. Thank you for serving every single one of our team and subjects with excellence. Making everyone feel seen and known and loved. You were a gift to this project and I’m honored to know you and call you friend.</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleRight}>Bob & Linda Lotich</div>
                            <div style={styles.singleThankDescriptionRight}>Thank you for being our friends and supporting us, championing and praying with us. Thank you for carrying this with us.</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleRight}>Alex & Henry Seeley</div>
                            <div style={styles.singleThankDescriptionRight}>Best Pastors ever. Thank you for being forever in the Corwin Corner, for being our cheerleaders and guiding and praying this project over the finish line. We love you!</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleRight}>Avenir Creative House - Jay Argaet, Yasmeen Audrelia, Hannah Hastings, Luke Hastings and Maddi Hewitt</div>
                            <div style={styles.singleThankDescriptionRight}>Thank you so much for every piece of heaven sent creativity and vision. You are gift to this world, to me and But God. Thank you so much for every piece of design, social media and marketing. I’m forever grateful. </div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleRight}>Align Retouching</div>
                            <div style={styles.singleThankDescriptionRight}>Thank you Align for every detail of retouching, every email, comment and quick turnaround. You elevated these images far beyond I could have ever imagined.</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleRight}>Mark Maxwell</div>
                            <div style={styles.singleThankDescriptionRight}>Thank you so much for your wisdom and legal counsel over the last year, every contract, conversation and crossed t and dotted i.	</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleRight}>Ash Wright</div>
                            <div style={styles.singleThankDescriptionRight}>Ash, you are one of the greatest visual storytellers. I am so freaking honored that you captured these special moments on set and that I’ll have these for a lifetime!</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleRight}>Stormlight Pictures</div>
                            <div style={styles.singleThankDescriptionRight}>Thank you Stormlight for hosting us at the studio to create this book, for being so accommodating I’m so grateful.</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleRight}>Watoto International</div>
                            <div style={styles.singleThankDescriptionRight}>To the people of Uganda, and the people of Watoto. Thank you for being the biggest inspiration. You changed my life nine years ago. Your lives have impacted me greatly and this book would not be happening without you.</div>
                        </div>
                        <div style={styles.singleThankContainer}>
                            <div style={styles.singleThankTitleRight}></div>
                            <div style={styles.singleThankDescriptionRight}>Nicole Mostaccio Edwards, Lakewood Church, Joe Gonzalez, Matt Daniel, Eunice Luis, Cathy Garcia, Liam Popoca, April Osteen, Lisa Osteen, Saints Church, Penny Mack, Jami Garcia, Tiffany Beye, Kristi Brazell, Michael Beckham, Mona Corwin  Warren Corwin.</div>
                        </div>
                    </div>
                </div>
                <button
                    style={styles.buttonEshop}
                    onClick={() => window.open("https://www.shop.butgodbook.co/product-page/but-god", "_blank")}
                >
                    <h1 style={styles.textbutton}>Buy "BUT GOD" Book</h1>
                    <img
                        src="https://i.postimg.cc/W39tVtL4/left-arrow.png"
                        height="24"
                        alt="Image"
                    />
                </button>
                <div style={styles.footer}>
                    <div style={styles.singleFooter}>
                        <div style={styles.socialOptions}>
                            {/* <button style={styles.textSocial} onClick={() => window.open("https://www.instagram.com/butgodbook", "_blank")}>Instagram</button>
                            <button style={styles.textSocial} onClick={() => window.open("https://www.youtube.com/channel/UCh2y3Eo1IhL4_F5fElYRB2w", "_blank")}>YouTube</button>
                            <button style={styles.textSocial} onClick={() => window.open('mailto:info@butgodbook.co')}>Email</button> */}
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/HsxMQyMQ/instagram-white.png"
                                height="20"
                                onClick={() => window.open("https://www.instagram.com/butgodbook", "_blank")}
                                alt="Icon of Instagram"
                            />
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/cCVdg2Yk/email-white.png"
                                height="20"
                                onClick={() => window.open('mailto:info@butgodbook.co')}
                                alt="Icon of Email"
                            />
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/HxkYfQdz/youtube-white.png"
                                height="20"
                                onClick={() => window.open("https://www.youtube.com/channel/UCh2y3Eo1IhL4_F5fElYRB2w", "_blank")}
                                alt="Icon of YouTube"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
