import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Story8 from "../components/Story-08";

function Storty02Container() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hoverUrl, setHoverUrl] = useState("");

    const handleHover = (active, url) => {
        if (hoverUrl == "") {
            //  console.log('cambie a activo')
            setHoverUrl(url)
        }
    }
    const handleHoverOut = (active, url) => {
        if (hoverUrl != "") {
            //   console.log('desactivo')
            setHoverUrl("")
        }
    }

    return (
        <>
            <Helmet>
                <title>Hopes's Story | But God Book </title>
                <meta
                    name="description"
                    content="When I was seventeen, I was given a diagnosis of Poly Cystic Ovarian
Syndrome. Most women with this diagnosis have a really hard time getting
pregnant and many can't conceive at all. At the time of my diagnosis, I was
young and focused on going to college and becoming a professional singer. "
                />
            </Helmet>
            <Story8
                screenSize={windowWidth}
                handleHover={handleHover}
                handleHoverOut={handleHoverOut}
                hoverUrl={hoverUrl}
            />
        </>
    );
}

export default Storty02Container;