import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/NavbarWhite";

let year = new Date().getFullYear()

export default ({ screenSize, handleClick, handleDarkMode, darkModeState, handleLanguage, handleChange, handleSubmit, language, page, firstName, lastName, numberPhone, mail, message, messageSent }) => {
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItem: 'center',
            justifyContent: 'space-between',
            backgroundColor: `${darkModeState ? ("hsl(230, 17%, 14%)") : ("hsl(0, 0%, 100%)")}`,
            height: '100vh',
        },
        email: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            //   zIndex: 100,
            //    height: '100px',
            fontSize: `${screenSize > 800 ? ("100px") : ("35px")} `,
            color: '#000',
            fontFamily: "BigCaslon",
            lineHeight: '48px',
            //   fontWeight: 600
        },
        footer: {
            display: "flex",
            flexDirection: `${screenSize > 800 ? ("row") : ("column")}`,
            margin: "0px 60px 50px",
            alignItems: "center",
            justifyContent: "space-between",
        },
        singleFooterLeft: {
            display: "flex",
            flex: 1,
            alignSelf: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            justifyContent: "flex-start",
        },
        singleFooter: {
            display: "flex",
            flex: 1,
            height: '100%',
            alignItems: "flex-start",
            justifyContent: "center",
            //position: "absolute",
        },
        line: {
            display: 'flex',
            width: "216px",
            height: "2px",
            alignSelf: 'flex-end',
            // justifyContent: 'flex-end',
            //  left: "648px",
            //    top: "930px",
            backgroundColor: '#000'
        },
        singleFooterRight: {
            display: "flex",
            flex: 1,
            alignSelf: `${screenSize > 800 ? ("flex-end") : ("center")}`,
            justifyContent: `${screenSize > 800 ? ("flex-end") : ("center")}`,

        },
        socialOptions: {
            display: "flex",
            flexDirection: 'row',
            margin: '0px'
        },
        imageSocial: {
            margin: `${screenSize > 800 ? ("0px 15px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
        },
        textSocial: {
            margin: `${screenSize > 800 ? ("0px 20px 0px") : ("0px 10px 0px")} `,
            fontSize: 16,
            color: '#000',
            fontFamily: "BigCaslon",
            outline: 0,
            backgroundColor: 'transparent',
            border: 'none'
        },
        textFooter: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '14px',
            textAlign: `${screenSize > 800 ? ("center") : ("left")} `,
            color: '#4f5766',
            margin: `${screenSize > 800 ? ("0px 120px") : ("0px 30px")} `,
            lineHeight: '1.7',
        },
        titleNavbar: {
            //  marginBottom: `${screenSize > 800 ? ('5px') : ("10px")}`,
            margin: 0,
            fontSize: "28px",
            color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,
            fontFamily: "InterBold",
            lineHeight: '1.4'
        },
        buttonNavbar: {
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '120px',
            outline: 0
        },
        buttonLanguage: {
            backgroundColor: 'transparent',
            border: 'none',
            padding: '3px 18px',
            height: '35px',
            width: '70px',
            outline: 0
        },
        textButton: {
            margin: 0,
            fontSize: "14px",
            color: '#fff',
        },
        textThanks: {
            margin: 0,
            fontSize: "16px",
            color: '#fff',
            alignSelf: 'centerr'
        },
        textButtonLanguage: {
            margin: 0,
            fontSize: "14px",
            color: '#333c4e',
        },
        textButtonLanguageHeader: {
            margin: 0,
            fontSize: 12,
            color: '#FFF',
        },
        textsHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        titleHeader: {
            color: '#fff',
            fontSize: '52px',
            fontFamily: 'sans-serif',
            fontWeight: '600',
            margin: `${screenSize > 800 ? ('0px 20px 8px') : ('0px 20px 20px')}`,
            textAlign: 'center'
        },
        subtitleHeader: {
            color: '#fff',
            fontSize: '18px',
            fontFamily: 'sans-serif',
            fontWeight: '400',
            margin: "0px 40px 30px",
            textAlign: 'center'
        },
        divButtonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            height: '10vh'
        },
        buttonHeader: {
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            // padding: '3px 18px',
            height: '40px',
            width: '40px',
            color: '#fff',
            outline: 0,
        },
        columnContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerReverse: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap-reverse')}`,
        },
        columnContainerNoBottom: {
            display: "flex",
            margin: '50px 0px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTop: {
            display: "flex",
            margin: '0px 0px 50px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnContainerNoTopNoBottom: {
            display: "flex",
            margin: `${screenSize > 800 ? ('0px') : ('30px 0px 0px')}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('nowrap') : ('wrap')}`,
        },
        columnText: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            //   width: `${screenSize > 800 ? ("50vw") : ("80vw")}`,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
        },
        columnTextGrey: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
            margin: 0,
            width: `${screenSize > 800 ? ("50vw") : ("100%")}`,
            padding: `${screenSize > 800 ? (0) : ("10px")}`,
            backgroundColor: "#F5F7FA",
            height: `${screenSize > 800 ? ("480px") : (<></>)} `,
        },
        columnTextPadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
        },
        columnTextContext: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            color: '#a4abae',
            lineHeight: '1.45',
            margin: "0px 0px 15px 0px",

        },
        columnTextTitle: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '27px',
            color: '#333c4e',
            lineHeight: '1.3',
            margin: "0px 0px 30px 0px",
        },
        columnTextBody: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "20px 0px 0px",
        },
        titleCaseContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
        },
        columnCaseContainer: {
            display: "flex",
            margin: '50px 0px',
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: `${screenSize > 800 ? ('wrap') : ('wrap')}`,
        },
        columnCase: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : ("230px")}`,
            margin: 0,
            padding: `${screenSize > 800 ? (0) : ("20px")}`,
            backgroundColor: "#005093"
        },
        imageCase: {
            height: `${screenSize > 800 ? ("45px") : ("35px")}`,
        },
        imageCase2: {
            height: `${screenSize > 800 ? ("75px") : ("55px")}`,
        },
        imageCase3: {
            height: `${screenSize > 800 ? ("45px") : ("30px")}`,
        },
        columnCaseText: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("345px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCaseTextRecordati: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            width: `${screenSize > 800 ? ("30vw") : ("80vw")}`,
            height: `${screenSize > 800 ? ("445px") : (<></>)}`,
            margin: 0,
            backgroundColor: '#F5F7FA'
        },
        columnCasePadding: {
            padding: `${screenSize > 800 ? ("0px 80px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnCasePaddingRecordati: {
            padding: `${screenSize > 800 ? ("0px 60px") : ("20px")}`,
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
        },
        columnTextCase: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: "30px 0px",
        },
        columnTextCaseNoTop: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("0px 0px 30px") : ("20px 10px 20px")}`,
        },
        columnTextCaseRecordati: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '17px',
            textAlign: 'start',
            color: '#4f5766',
            lineHeight: '1.7',
            margin: `${screenSize > 800 ? ("10px 0px") : ("20px 10px 20px")}`,
        },
        columnBoldCase: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '16px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            margin: 0,
            // padding: 0,
            padding: `${screenSize > 800 ? (0) : ("0px 0px 10px")}`,
        },
        titleClientContainer: {
            display: "flex",
            margin: '50px 50px 0px',
            alignItems: "center",
            justifyContent: 'center',
        },
        titleClient: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '26px',
            textAlign: 'center',
            color: '#333c4e',
            margin: 0,
            lineHeight: '1.7',
        },
        columnClientContainer: {
            display: "flex",
            margin: `${screenSize > 800 ? ('50px') : ("30px 30px 50px")}`,
            alignItems: "center",
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        imageClient: {
            height: 70,
            margin: 25
        },
        formContainer: {
            backgroundColor: "#333c4e",
            alignItems: "center",
            justifyContent: 'center',
            padding: `${screenSize > 800 ? ("100px") : ("100px 30px")}`,
            margin: '50px 0px'
        },
        titleForm: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '28px',
            textAlign: 'center',
            color: '#fff',
            margin: "0px 50px 70px",
            lineHeight: '1.7',
        },
        formCenter: {
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
        },
        formInputsContainer: {
            display: "flex",
            // flex: 12,
            width: '440px',
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'center',
        },
        labelForm: {
            margin: 0,
            color: '#fff'
        },
        inputForm: {
            borderRadius: '25px',
            height: '40px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '0px 16px'
        },
        inputFormMessage: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            borderRadius: '15px',
            height: '160px',
            margin: "8px 0px 16px",
            fontFamily: 'sans-serif',
            fontWeight: '400',
            padding: '10px 16px',
        },
        buttonForm: {
            display: "flex",
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: '#4DC2F1',
            borderRadius: 25,
            border: 'none',
            padding: '13px 23px',
            margin: "22px 0px",
            height: '50px',
            outline: 0
        },
        columnAddressContainer: {
            display: "flex",
            // margin: '50px 120px 100px',
            margin: `${screenSize > 800 ? ('50px 120px 100px') : ("50px 30px")}`,
            alignItems: "flex-start",
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        singleAddress: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "flex-start",
            justifyContent: 'flex-start',
            width: "250px",
            //height: 70,
            margin: "0px 0px 25px"
        },
        titleAddress: {
            fontFamily: 'sans-serif',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
            color: '#333c4e',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 15px",
        },
        addressText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4f5766',
            lineHeight: '1.7',
            textAlign: 'left',
            margin: "0px 0px 3px",
        },
        phoneText: {
            fontFamily: 'sans-serif',
            fontWeight: '400',
            fontSize: '18px',
            textAlign: 'center',
            color: '#4dc2f1',
            lineHeight: '1.7',
            margin: 0,
            textAlign: 'left',
        },
        buttonEshop: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            width: `${screenSize > 800 ? ('300px') : ("60vw")} `,
            padding: `${screenSize > 800 ? ("12px 25px") : ("8px 20px")}`,
            // padding: '0px 50vw 0px 45px',
            margin: `${screenSize > 800 ? ("80px 0px 0px") : ("40px 0px 0px")}`,
            backgroundColor: '#000',
            borderRadius: '50px',
            border: '1px solid #000',
            //  position: "fixed",
            //  bottom: `${screenSize > 800 ? (0) : ("0")} `,
            zIndex: 100,
        },
        buttonEshop2: {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            width: `${screenSize > 800 ? ('300px') : ("60vw")} `,
            padding: `${screenSize > 800 ? ("12px 25px") : ("8px 20px")}`,
            // padding: '0px 50vw 0px 45px',
            margin: `${screenSize > 800 ? ("20px 0px 0px") : ("20px 0px 0px")}`,
            backgroundColor: '#000',
            borderRadius: '50px',
            border: '1px solid #000',
            //  position: "fixed",
            //  bottom: `${screenSize > 800 ? (0) : ("0")} `,
            zIndex: 100,
        },
        textbutton: {
            fontSize: 16,
            fontFamily: "BigCaslon",
            color: '#fff',
            display: "flex",
            alignSelf: 'center',
            margin: 0,
            padding: 0,
        },
        textbuttonMobile: {
            fontSize: 18,
            fontFamily: "BigCaslon",
            color: '#fff',
            display: "flex",
            alignSelf: 'center',
            margin: 0,
            padding: 0,
            padding: "5px 10px 5px 10px",
            textAlign: "center",
            lineHeight: '25px'
        },
        logoContainer: {
            margin: `${screenSize > 800 ? ("0px") : ("0px 0px 20px")}`
        }
    };
    return (
        <>
            <Navbar style={{ zIndex: 999 }} />
            <div style={styles.container} id="scroller">
                <div style={{ height: 140 }} />
                <div style={{ display: "flex", flexDirection: "column", }}>
                    <div style={styles.email}>EXHIBITION</div>
                    <button
                        onClick={() => window.open("https://www.shop.butgodbook.co/", "_blank")}
                        style={styles.buttonEshop}
                    >
                        {/* {screenSize > 800 ? (<h1 style={styles.textbutton}>Sign up for book release updates</h1>) : (<h1 style={styles.textbuttonMobile}>Sign up for book release updates</h1>)} */}
                        {screenSize > 800 ? (<h1 style={styles.textbutton}>Be the first to know</h1>) : (<h1 style={styles.textbuttonMobile}>Be the first to know</h1>)}
                    </button>
                    <button
                        onClick={() => window.open("https://www.shop.butgodbook.co/product-page/but-god", "_blank")}
                        style={styles.buttonEshop2}
                    >
                        {/* {screenSize > 800 ? (<h1 style={styles.textbutton}>Sign up for book release updates</h1>) : (<h1 style={styles.textbuttonMobile}>Sign up for book release updates</h1>)} */}
                        {screenSize > 800 ? (<h1 style={styles.textbutton}>Buy "BUT GOD" Book</h1>) : (<h1 style={styles.textbuttonMobile}>Buy "BUT GOD" Book</h1>)}
                    </button>
                </div>
                <div style={styles.footer}>
                    <div style={styles.singleFooterLeft}>
                        <img
                            src="https://i.postimg.cc/5ycMdM7r/But-God-Logo.png"
                            height="44"
                            style={styles.logoContainer}
                            alt="Logo of But God"
                            loading="lazy"
                        />
                    </div>
                    {screenSize > 800 ? (
                        <div style={styles.singleFooter}>
                            <div style={styles.line} />
                        </div>
                    ) : (<></>)}

                    <div style={styles.singleFooterRight}>
                        <div style={styles.socialOptions}>
                            {/* <button style={styles.textSocial} onClick={() => window.open("https://www.instagram.com/butgodbook", "_blank")}>Instagram</button>
                            <button style={styles.textSocial} onClick={() => window.open("https://www.youtube.com/channel/UCh2y3Eo1IhL4_F5fElYRB2w", "_blank")}>YouTube</button>
                            <button style={styles.textSocial} onClick={() => window.open('mailto:info@butgodbook.co')}>Email</button> */}
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/9X4R0hHp/instagram-black.png"
                                height="20"
                                onClick={() => window.open("https://www.instagram.com/butgodbook", "_blank")}
                                alt="Icon of Instagram"
                            />
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/8PQ5CfHp/email-black.png"
                                height="20"
                                onClick={() => window.open('mailto:info@butgodbook.co')}
                                alt="Icon of Email"
                            />
                            <img
                                style={styles.imageSocial}
                                src="https://i.postimg.cc/1X63Hy91/youtube-black.png"
                                height="20"
                                onClick={() => window.open("https://www.youtube.com/channel/UCh2y3Eo1IhL4_F5fElYRB2w", "_blank")}
                                alt="Icon of YouTube"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
